import * as React from 'react';


import Button from '@material-ui/core/Button';

import AudioPlayer from 'react-h5-audio-player';

import { GroupPanel } from "../Layouts"
import { Typography } from '@material-ui/core';
import { Player } from 'video-react';


import { observer } from "mobx-react";

import 'video-react/dist/video-react.css';
import 'react-h5-audio-player/lib/styles.css';

import ImagesSlider from 'components/ImagesSlider';

const Heading1Preview = observer(({element}) => {
    return <h1>{element.value}</h1>
});

const Heading2Preview = observer(({element}) => {
    return <h2>{element.value}</h2>
});


const ParagraphPreview = observer(({element}) => {
    return <p>{element.value}</p>
});

const HtmlContentPreview = observer(({element}) => {
    return <div dangerouslySetInnerHTML={{ __html: element.value }}></div>;
});

const ImageElementPreview = observer(({element}) => {
    // if a image isn't selected yet the defaultImage is empty
    const imageUrl =  element.rendition.defaultImage ? element.rendition.defaultImage.url : null;
    if (imageUrl){
        return <img alt="" style={{ width: "100%" }} src={imageUrl} />
    }else{
        return <div>No image choosen yet</div>
    } 
});

const ImagesElementPreview = observer(({element}) => {
    // if a image isn't selected yet the defaultImage is empty

    const images = element.values.map((imageElement)=>{
        const url =  imageElement.rendition.defaultImage ? imageElement.rendition.defaultImage.url : null;
        return {url}
    })

    if (images ){
        return <ImagesSlider images={images}/>
    }else{
        return <div>No image choosen yet</div>
    } 
});

const AudioElementPreview = observer(({element}) => {
    const src =  element.rendition.base ? element.rendition.base.url: null;
    if (src){
        return <AudioPlayer src={src} />
    }else{
        return <div>No audio choosen yet</div>
    }
});


const VideoElementPreview = observer(({element}) => {
    const src =  element.rendition.base ? element.rendition.base.url: null;
    if (src){
        const imageUrl = element.rendition.defaultImage? element.rendition.defaultImage.url: null;
        return <Player
            poster={imageUrl}
            src={element.rendition.base.url} />
    }else{
        return <div>No video choosen yet</div>
    }
});

const MultipleChoiceQuestionRenderer = observer(({element}) => {

    const multipleChoiceQuestion = element;
    const answers = multipleChoiceQuestion.answers.map( multipleChoiceAnswer => {
        return <Typography variant="caption" display="block" gutterBottom>{multipleChoiceAnswer.answer}</Typography>
    });
    return <React.Fragment>
        <Typography variant="h3" gutterBottom>{multipleChoiceQuestion.question}</Typography>
        {answers}

    </React.Fragment>
});

const SendPostcardElementRenderer = observer(({element}) => {

    const postCardElement = element;
    const buttonLabel = element.label ? element.label: "Send postcard";

    return <React.Fragment>
       <div>
            <Button variant="outlined" color="secondary" size="large">{buttonLabel}</Button>
       </div>
        

    </React.Fragment>
});

const clazzToPreviewRendererMap = {
    "content_objects.model.Heading1": Heading1Preview,
    "content_objects.model.Heading2": Heading2Preview,
    "content_objects.model.Paragraph": ParagraphPreview,
    "content_objects.model.HtmlContentElement": HtmlContentPreview,
    "content_objects.model.ImageElement": ImageElementPreview,
    "content_objects.model.ImagesElement": ImagesElementPreview,
    "content_objects.model.VideoElement": VideoElementPreview,
    "content_objects.model.AudioElement": AudioElementPreview,
    "content_objects.model.MultipleChoiceQuestion": MultipleChoiceQuestionRenderer,
    "content_objects.model.SendPostcardElement": SendPostcardElementRenderer,
}

export const StoryBlockRenderer = ({storyblock}) => {

    const name = storyblock.name ? storyblock.name : "";
    const contentElements = storyblock.children ? storyblock.children.map((childElement, index) => {
      
        if (childElement){
            const clazzName = childElement.$classDescription.clazzName;
    
            const Renderer = clazzToPreviewRendererMap[clazzName];
            if (Renderer) {
                return <Renderer key={index} element={childElement} />
            } else {
                return <div>no renderer for {clazzName}</div>
            }
        }
        return null;
    }) : null;

    return (<React.Fragment>
        <GroupPanel groupName={name} />
        {contentElements}
    </React.Fragment>)
}

export default observer(StoryBlockRenderer);
